import { safeURL } from './urlUtils'

export const MarkdownLinkTempParam = '_mdAttr'

/**
 * Parses italic text from backend markdown, that is not caught by 'marked' package.
 *
 * @param md
 */
export function parseItalic(md: string): string {
  return String(md).replace(/( |^)_(?!blank)(.*?)_(?!blank)/gm, '$1<em>$2</em>')
}

/**
 * Parses cite text from backend markdown, that is not caught by 'marked' package.
 *
 * @param md
 */
export function parseCite(md: string): string {
  return String(md).replace(/(^|[^~])(?:~([^~]+)~)/, '$1<cite>$2</cite>')
}

/**
 * Adds custom attribute(s) to video markdown.
 *
 * @param md
 */
export function parseVideo(md: string): string {
  return md.replace(/(\<video)/, `$1 playsinline=true `)
}

/**
 * Handles our custom way of implementing custom attributes to Markdown links.
 *
 * @param md
 */
export function parseLinks(md: string) {
  ;[
    ...md.matchAll(/\[\s*(.*?)\s*\]\(\s*(.*?)\s*\{\s*(.*?)\s*\}\s*\)/g),
  ].forEach(link => {
    const safeUrl = safeURL(link[2])
    if (safeUrl) {
      safeUrl.searchParams.append(MarkdownLinkTempParam, `{${link[3]}}`)
      md = md.replaceAll(
        link[0],
        `[${link[1]}](${
          isRelativeUrl(link[2])
            ? link[2].startsWith('#')
              ? useRoute().path
              : safeUrl.pathname
            : `${safeUrl.origin}${safeUrl.pathname}`
        }${safeUrl.search}${safeUrl.hash})`
      )
    }
  })
  return md + ' '
}

/**
 * Makes sure newline characters are the same all over.
 * Backend uses \n as newline.
 *
 * @param md
 */
export function parseNewLines(md: string) {
  return md.replace(/(?:\r\n|\r|\n)/g, '\n')
}

/**
 * If a string is only whitespace, the MD parser fails. So we remove instances with this.
 *
 * @param md
 */
export function handleOnlyWhitespace(md: string) {
  return md.replaceAll(/\s/g, '').length === 0 ? '' : md
}
